import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutPage from "../pages/About";
import Home from '../pages/Home/Home';
import { history } from '../helpers'

import AuthLayoutRoute from "../components/Layouts/AuthLayout";
import DashboardLayoutRoute from "../components/Layouts/DashboardLayout";
import UserLayoutRoute from "../components/Layouts/UserLayout";

import SetPassword from "../pages/UserSetPassword/SetPassword";
import SignupForm from '../pages/UserSetPassword/SignupForm';
import LinkExpired from '../pages/UserSetPassword/LinkExpired';
import PasswordSuccess from '../pages/UserSetPassword/PasswordSuccess';

import SignIn from '../pages/Authentication/SignIn/SignIn';
import ForgotPassword from '../pages/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/Authentication/ResetPassword/ResetPassword';
import ChangePassword from '../pages/Authentication/ChangePassword/ChangePassword';
import Otp from '../pages/Authentication/Otp/Otp';
import Verify from '../pages/Authentication/Verify/Verify';
import Questionnaires from '../pages/Questionnaires/Questionnaires';
import EpiQuestionnaires from '../pages/Questionnaires/EpiQuestionnaires';
import EpiFolloWQuestionnaires from '../pages/Questionnaires/EpiFollowQuestionnaires';
import VirusRisk from '../pages/VirusRisk/VirusRisk';

import DnaReport from '../pages/DnaReport/DnaReport';
import HealthInsight from '../pages/DnaReport/HealthInsight';
import EpigeneticReport from '../pages/EpigeneticReport/EpigeneticReport';
import PractitionersReport from '../pages/PractitionersReport/PractitionersReport';
import CongenitalReport from '../pages/CongenitalReport/CongenitalReport';
import DiseaseRiskReport from '../pages/DiseaseRisk/DiseaseRiskReport';
import UserManagement from '../pages/UserManagement';
import Lifestyle from '../pages/Lifestyle'
import Error404 from '../pages/Authentication/404/404';
import Error404External from '../pages/Authentication/404/404-external';
import DepartManagement from '../pages/DepartManagement';
import ManagementInsight from '../pages/ManagementInsight';
import BusinessInsight from '../pages/BusinessInsight';
import Billing from '../pages/Billing';
import BloodResults from '../pages/BloodResult/BloodResults';
import BloodKits from '../pages/BloodResult/BloodKits';
import CognitiveSkills from '../pages/Cognitive/CognitiveSkills';
import CognitiveDomain from '../pages/Cognitive/CognitiveDomain';
import FaceScanResult from '../pages/FaceScanResults/FaceScanResult';
import Dna12Result from '../pages/Dna12Result/Dna12Result';

class Routes extends React.Component {

    render() {
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <DashboardLayoutRoute path="/" exact={true} component={Home} />
                        <DashboardLayoutRoute path="/usermanagement" exact={true} component={UserManagement} />
                        <DashboardLayoutRoute path="/deptmanagement" exact={true} component={DepartManagement} />
                        <DashboardLayoutRoute path="/management" exact={true} component={ManagementInsight} />
                        <DashboardLayoutRoute path="/business" exact={true} component={BusinessInsight} />

                        {/* Questioner */}
                        <DashboardLayoutRoute path="/questionnaires/dna" exact={true} component={Questionnaires} />
                        <DashboardLayoutRoute path="/questionnaires/epi" exact={true} component={EpiQuestionnaires} />
                        <DashboardLayoutRoute path="/questionnaires/epifollow" exact={true} component={EpiFolloWQuestionnaires} />

                        {/* Blood Results */}
                        <DashboardLayoutRoute path="/bloodresults" exact={true} component={BloodResults} />
                        <DashboardLayoutRoute path="/bloodkits" exact={true} component={BloodKits} />

                        {/* DNA Report */}
                        <DashboardLayoutRoute path="/dnareport" exact={true} component={DnaReport} />
                        <DashboardLayoutRoute path="/dnareport/dna" exact={true} component={DnaReport} />
                        <DashboardLayoutRoute path="/dnareport/healthinsight" exact={true} component={HealthInsight} />
                        {/* <DashboardLayoutRoute path="/healthinsight" exact={true} component={HealthInsight} /> */}

                        {/* Epigenetic Report */}
                        <DashboardLayoutRoute path="/epigeneticreport" exact={true} component={EpigeneticReport} />
                        <DashboardLayoutRoute path="/practitionersreport" exact={true} component={PractitionersReport} />

                        {/* DNA 12 */}
                        <DashboardLayoutRoute path="/dna12" exact={true} component={Dna12Result} />

                        {/* Congenital Report */}
                        <DashboardLayoutRoute path="/congenitalreport" exact={true} component={CongenitalReport} />

                        {/* Disease Risk Report */}
                        <DashboardLayoutRoute path="/diseaserisk" exact={true} component={DiseaseRiskReport} />

                        {/* FaceScanReport */}
                        <DashboardLayoutRoute path="/facescanreport" exact={true} component={FaceScanResult} />

                        {/* Cognitive */}
                        <DashboardLayoutRoute path="/cognitivedomains" exact={true} component={CognitiveDomain} />
                        <DashboardLayoutRoute path="/cognitiveskills" exact={true} component={CognitiveSkills} />

                        {/* Lifestyle */}
                        <DashboardLayoutRoute path="/lifestyle" exact={true} component={Lifestyle} />
                        {/* Virus Risk */}
                        <DashboardLayoutRoute path="/virusrisk" exact={true} component={VirusRisk} />
                        <DashboardLayoutRoute path="/billing" exact={true} component={Billing} />

                        {/* <DashboardLayoutRoute path="/lifestyle/:page" exact={true} component={OverviewDetails} alert={this.props.alert} handleClose={this.handleClose} /> */}

                        <Route path="/about" component={AboutPage} />

                        <AuthLayoutRoute exact={true} path="/signin" component={SignIn} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/signin" component={SignIn} partner={7} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy" component={SignIn} partner={7} />

                        <AuthLayoutRoute exact={true} path="/eterna-life/signin" component={SignIn} partner={50} />
                        <AuthLayoutRoute exact={true} path="/eterna-life" component={SignIn} partner={50} />

                        <AuthLayoutRoute exact={true} path="/lifetime-health/signin" component={SignIn} partner={48} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health" component={SignIn} partner={48} />

                        <AuthLayoutRoute exact={true} path="/biolimitless/signin" component={SignIn} partner={52} />
                        <AuthLayoutRoute exact={true} path="/biolimitless" component={SignIn} partner={52} />

                        <AuthLayoutRoute exact={true} path="/forgotpassword" component={ForgotPassword} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/forgotpassword" component={ForgotPassword} partner={7} />
                        <AuthLayoutRoute exact={true} path="/eterna-life/forgotpassword" component={ForgotPassword} partner={50} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health/forgotpassword" component={ForgotPassword} partner={48} />
                        <AuthLayoutRoute exact={true} path="/biolimitless/forgotpassword" component={ForgotPassword} partner={52} />

                        <AuthLayoutRoute exact={true} path="/resetpassword/:token" component={ResetPassword} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/resetpassword/:token" component={ResetPassword} partner={7} />
                        <AuthLayoutRoute exact={true} path="/eterna-life/resetpassword/:token" component={ResetPassword} partner={50} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health/resetpassword/:token" component={ResetPassword} partner={48} />
                        <AuthLayoutRoute exact={true} path="/biolimitless/resetpassword/:token" component={ResetPassword} partner={52} />


                        <AuthLayoutRoute exact={true} path="/changepassword/:token" component={ChangePassword} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/changepassword/:token" component={ChangePassword} partner={7} />
                        <AuthLayoutRoute exact={true} path="/eterna-life/changepassword/:token" component={ChangePassword} partner={50} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health/changepassword/:token" component={ChangePassword} partner={48} />
                        <AuthLayoutRoute exact={true} path="/biolimitless/changepassword/:token" component={ChangePassword} partner={52} />

                        <AuthLayoutRoute exact={true} path="/otp" component={Otp} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/otp" component={Otp} partner={7} />
                        <AuthLayoutRoute exact={true} path="/eterna-life/otp" component={Otp} partner={50} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health/otp" component={Otp} partner={48} />
                        <AuthLayoutRoute exact={true} path="/biolimitless/otp" component={Otp} partner={52} />

                        <AuthLayoutRoute exact={true} path="/verify/:token" component={Verify} />
                        <AuthLayoutRoute exact={true} path="/bio-synergy/verify/:token" component={Verify} partner={7} />
                        <AuthLayoutRoute exact={true} path="/eterna-life/verify/:token" component={Verify} partner={50} />
                        <AuthLayoutRoute exact={true} path="/lifetime-health/verify/:token" component={Verify} partner={48} />
                        <AuthLayoutRoute exact={true} path="/biolimitless/verify/:token" component={Verify} partner={52} />

                        <UserLayoutRoute exact={true} path="/setpassword/:token" component={SetPassword}></UserLayoutRoute>
                        <UserLayoutRoute exact={true} path="/usersignup" component={SignupForm}></UserLayoutRoute>
                        <UserLayoutRoute exact={true} path="/linkexpired" component={LinkExpired}></UserLayoutRoute>
                        <UserLayoutRoute exact={true} path="/signupsuccess" component={PasswordSuccess}></UserLayoutRoute>

                        {/* <DashboardLayoutRoute component={Error404} /> */}
                        <AuthLayoutRoute path="*" component={Error404External} />

                    </Switch>
                </Router>
            </div>
        );
    }
}

export default Routes;
